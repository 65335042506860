<template>
  <div class="category-item" @click="toPush(data.id)">
    <el-image
      style="width: 140px; height: 140px"
      :src="data.image"
      fit="fit"
    ></el-image>
    <div style="margin-top: 10px; color: rgba(0, 0, 0, 0.8)">
      {{ data.name }}
    </div>
  </div>
</template>
<script>
export default {
  name: "CategoryItem",
  props: ["data"],
  data() {
    return {};
  },
  methods: {
    toPush(id) {
      this.$parent.toCategory(id)
    },
  },
};
</script>
<style lang="less" scoped>
.category-item {
  width: 170px;
  height: 200px;
  background: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: center;
  font-size: 14px;
      cursor: pointer;
}
.category-item:hover {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 6px 0 rgba(220, 220, 220, 0.6);
  z-index: 1;
}
</style>