<template>
  <div class="allCatefory">
    <div class="main-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">回首页</el-breadcrumb-item>
        <el-breadcrumb-item>全部分类</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="background: #fff; padding-bottom: 40px">
        <div class="catefory-list">
          <CategoryItem
            v-for="item in $store.state.homeMainData"
            :key="item.id"
            :data="item"
            @click="toCategory(item.id)"
          ></CategoryItem>
        </div>
        <div
          style="
            padding: 0 80px;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
          "
        >
          <span
            v-for="(item, i) in letter"
            :key="i"
            style="color: rgba(0, 0, 0, 0.14)"
          >
            {{ item }}<span v-if="i != 25" style="margin-left: 14px">·</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryItem from "@/components/category/CategoryItem";
export default {
  components: {
    CategoryItem,
  },
  data() {
    return {
      letter: [],
    };
  },
  created() {
    for (let i = 0; i < 26; i++) {
      this.letter.push(String.fromCharCode(65 + i));
    }
  },
  methods: {
    toCategory(id) {
      sessionStorage.CATEGORYID = id;
      this.$router.push("goodsSearchs");
      window.scrollTo(0, 0);
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.allCatefory {
  background: #f5f5f5;
  padding: 20px;
  font-size: 14px;
  color: #000;
  .catefory-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
}
</style>